import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker";

class DateRangePicker {
  constructor(fromSelector, toSelector, options = {}) {
    this.format = options.format || "dd M yy";
    this.toDate = null;
    this.options = options;
    this.fromDate = null;
    this.currency = options.currency || "EUR";
    this.toDateObj = null;
    this.priceData = null;
    this.toSelector = toSelector;
    this.fromDateObj = null;
    this.fromSelector = fromSelector;
    this.smartCalendar = options.smartCalendar ?? true;
    this.currencySymbol = options.currencySymbol || "";
    this.presetHighlight = true;
    this.showCalendarPrice = options.showCalendarPrice || false;

    this.init(fromSelector, toSelector);
  }

  highlightDates(date) {
    if (this.fromDateObj && date.getTime() === this.fromDateObj.getTime()) {
      return [true, "from-highlight"];
    }
    if (this.toDateObj && date.getTime() === this.toDateObj.getTime()) {
      return [true, "to-highlight"];
    }
    if (!this.fromDateObj || !this.toDateObj) return [true, ""];
    return date >= this.fromDateObj && date <= this.toDateObj
      ? [true, "range-highlight"]
      : [true, ""];
  }

  setPrices(showPrice) {
    if (this.smartCalendar) {
      const self = this;
      $("#ui-datepicker-div td a").removeClass("show-price hide-price");
      $("#ui-datepicker-div td a").addClass(
        showPrice ? "show-price" : "hide-price"
      );
      if (self.priceData) {
        $("#ui-datepicker-div td").each(function () {
          const cellDate = self.getCellDateInstance($(this));
          if (cellDate) {
            const dateString = cellDate.toLocaleDateString("en-CA");
            if (dateString in self.priceData) {
              $(this)
                .find("a")
                .attr(
                  "data-content",
                  `${self.currencySymbol}${self.priceData[dateString]}`
                );
            }
          }
        });
      }
    }
  }

  showPriceToggleSection() {
    if (this.smartCalendar) {
      const priceControl = $(
        `<div class="datepicker-price-control">
          <div class="d-flex justify-content-end">
            <div class="mr-5">
              <div style="line-height: 13px; font-size: 14px;"><b>Show Prices</b></div>
              <span class="price-currency text-main mt-0">(All Prices in ${
                this.currency
              })</span>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" ${
                this.showCalendarPrice ? "checked" : ""
              } class="custom-control-input" id="priceControlSwitch">
              <label class="custom-control-label" for="priceControlSwitch"></label>
            </div>
          </div>
        </div>`
      );

      $("#ui-datepicker-div").append(priceControl);
      $("#priceControlSwitch").on("change", () => {
        this.showCalendarPrice = !this.showCalendarPrice;
        this.setPrices(this.showCalendarPrice);
      });
      this.setPrices(this.showCalendarPrice);
    }
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
    return formattedDate;
  }

  setPriceData(data) {
    this.priceData = data;
    this.setPrices(this.showCalendarPrice);
  }

  getCellDateInstance($cell) {
    const dataMonth = parseInt($cell.attr("data-month"), 10);
    const dataYear = parseInt($cell.attr("data-year"), 10);
    const dataDate = parseInt($cell.find("a").attr("data-date"), 10);

    if (!isNaN(dataMonth) && !isNaN(dataYear) && !isNaN(dataDate)) {
      return new Date(dataYear, dataMonth, dataDate);
    }

    return null;
  }

  drawArrow(input) {
    const $input = $(input);
    const widget = $input.datepicker("widget");

    widget.addClass("direction-up");

    var arrow = widget.find(".datepicker-arrow");

    if (!arrow.length) {
      arrow = $('<div class="datepicker-arrow"/>')
        .css({
          width: 0,
          height: 0,
          borderStyle: "solid",
          margin: "0 10px",
          position: "absolute",
        })
        .appendTo(widget);
    }

    arrow.css({
      left: "20%",
      borderColor: "transparent transparent white",
      borderWidth: "0 19px 10px 19px",
      top: "-7px",
      bottom: "auto",
    });
  }

  addCloseButton() {
    const self = this;
    const $closeCal = $("<span>", { class: "close-cal" });

    const $closeLinks = $("<a>", { class: "close-links" });

    $closeLinks.on("click", function () {
      $(`${self.fromSelector}, ${self.toSelector}`).datepicker("hide");
    });

    $closeLinks.append($("<div>"), $("<div>"));
    $closeCal.append($closeLinks);

    $("#ui-datepicker-div").append($closeCal);
  }

  calendarVerticalOffset(input) {
    var dpDiv = $("#ui-datepicker-div");

    var inputOffset = $(input).offset();
    var inputHeight = $(input).outerHeight();

    dpDiv.css({
        top: inputOffset.top + inputHeight +  "px"
    });
  }

  presetDateHighlights() {
    const self = this;
    const { defaultFromDate, defaultToDate } = self.options;

    if (!self.fromDateObj && defaultFromDate) {
      self.fromDateObj = new Date(defaultFromDate);
      self.fromDate = self.formatDate(self.fromDateObj);
    }

    if (!self.toDateObj && defaultToDate) {
      self.toDateObj = new Date(defaultToDate);
      self.toDate = self.formatDate(self.toDateObj);
    }

    if (self.fromDateObj && self.toDateObj) {
      $("#ui-datepicker-div td").each(function () {
        const cellDate = self.getCellDateInstance($(this));
        if (cellDate && cellDate.getTime() === self.fromDateObj.getTime()) {
          $(this).addClass("from-highlight");
          $(this).removeClass("range-highlight");
        }

        if (
          cellDate &&
          cellDate > self.fromDateObj &&
          cellDate < self.toDateObj
        ) {
          $(this).addClass("range-highlight");
        }

        if (cellDate && cellDate.getTime() === self.toDateObj.getTime()) {
          $(this).addClass("to-highlight");
          $(this).removeClass("range-highlight");
        }
      });
    }
  }

  prepareDatepickerDisplay(instance) {
    setTimeout(() => {
      this.showPriceToggleSection();
      this.drawArrow(instance);
      this.addCloseButton();
      this.calendarVerticalOffset(instance);
    }, 10);
  }

  init(fromSelector, toSelector) {
    const self = this;

    $(fromSelector).datepicker({
      firstDay: 1,
      numberOfMonths: 2,
      dateFormat: self.format,
      minDate: new Date(),
      maxDate: "+1Y",
      beforeShowDay: this.highlightDates.bind(this),
      beforeShow: function () {
        self.prepareDatepickerDisplay(this);
        if (self.presetHighlight) {
          setTimeout(() => self.presetDateHighlights(), 100);
        }
      },
      onSelect: (selectedDate) => {
        this.presetHighlight = false;
        this.fromDate = selectedDate;
        this.fromDateObj = new Date(selectedDate);
        this.fromDateObj.setHours(0, 0, 0, 0);
        $(fromSelector).val(selectedDate);

        let minDate = new Date(this.fromDateObj);
        minDate.setDate(minDate.getDate() + 1);
        if (this.toDateObj && this.fromDateObj > this.toDateObj) {
          this.toDateObj = minDate;
        }
        $(toSelector)
          .datepicker("option", "minDate", minDate)
          .datepicker("refresh");
        $(fromSelector).datepicker("refresh");
        setTimeout(() => $(toSelector).focus(), 100);
      },
      onChangeMonthYear: () => {
        self.prepareDatepickerDisplay(this);
      },
    });

    $(toSelector).datepicker({
      firstDay: 1,
      numberOfMonths: 2,
      dateFormat: self.format,
      minDate: new Date(),
      maxDate: "+1Y",
      beforeShowDay: this.highlightDates.bind(this),
      beforeShow: function () {
        self.prepareDatepickerDisplay(this);

        if (self.presetHighlight) {
          setTimeout(() => self.presetDateHighlights(), 100);
        }

        if (!self.fromDate) {
          setTimeout(() => $(fromSelector).datepicker("show"), 10);
          return false;
        }
      },
      onSelect: (selectedDate) => {
        this.presetHighlight = false;
        this.toDate = selectedDate;
        this.toDateObj = new Date(selectedDate);
        this.toDateObj.setHours(0, 0, 0, 0);
        $(toSelector).val(selectedDate);
        $(`${fromSelector}, ${toSelector}`).datepicker("refresh");
        $(toSelector).blur();
      },
      onChangeMonthYear: () => {
        self.prepareDatepickerDisplay(this);
      },
    });

    $(document).on("mouseenter", "#ui-datepicker-div td", (event) => {
      if (!this.fromDateObj) return;

      const parsedDate = self.getCellDateInstance($(event.currentTarget));
      if (parsedDate) {
        $("#ui-datepicker-div td").each(function () {
          const cellDate = self.getCellDateInstance($(this));
          if (
            cellDate &&
            cellDate > self.fromDateObj &&
            cellDate < parsedDate
          ) {
            $(this).addClass("range-highlight");
          } else {
            $(this).removeClass("range-highlight");
          }
        });
      }
    });

    $(document).on("mouseleave", "#ui-datepicker-div td", () => {
      $("#ui-datepicker-div td").removeClass("range-highlight");
      $("#ui-datepicker-div td").each(function () {
        if (self.fromDateObj && self.toDateObj) {
          const cellDate = self.getCellDateInstance($(this));
          if (
            cellDate &&
            cellDate > self.fromDateObj &&
            cellDate < self.toDateObj
          ) {
            $(this).addClass("range-highlight");
          } else {
            $(this).removeClass("range-highlight");
          }
        } else {
          $(this).removeClass("range-highlight");
        }
      });
    });
  }
}

export default DateRangePicker;
